import {
  BasicResponsiveImage,
  Callout,
  Link,
  StandardTile,
  StandardTileContents,
  StandardTileImage,
  StandardTileName,
  StandardTileOverlay,
  Typography,
  StandardTileDescription
} from '@vp/swan'

import { TileWrapper } from '@vp/tile-wrapper'

import { getUrlIdForTracking } from '../utils/getUrlIdForTracking'
import { getTileCtaTrackingConfig } from './Analytics/trackingConfig'
import { createOnClickTrackingHandler } from './Analytics/trackingUtils'

import { CampaignCallout } from '@vp/campaign-callouts'
import { useTrackingContext } from '../hooks/TrackingContext'

const getTrackingDataNewTile = (tile) => {
  const { position, row, section, sectionHeader } = tile
  const tileHeader = tile.template?.tileDetails?.header?.value ?? ''
  const destinationUrl = tile.template?.tileDetails?.link?.href ?? ''

  const trackingData = {
    tileHeader,
    destinationUrl,
    position,
    row,
    section,
    sectionHeader,
    productKey: tile.template?.productKey ?? '',
    sourcePagePath: typeof window !== "undefined" ? window.location.pathname : "/",
  }
  return trackingData
}

const Tile = ({ useNewTiles, data, position, loadType, tileSkin, trackingPageZone, enableCampaignCallouts }) => {
  const {
    calloutText,
    calloutColor,
    transformedUrl,
    productImage,
    mpv,
    template,
    productTileHeader,
    productTileSubHeader,
  } = data

  const getTrackingConfiguration = () => {
    const urlId = getUrlIdForTracking(data.transformedUrl)
    const ctaValue = data.mpv?.mpvId || data.merchandisingElement?.mpvId || urlId || data.productTileHeader
    return getTileCtaTrackingConfig(data.transformedUrl.url, trackingPageZone, ctaValue, position)
  }
  const trackingConfiguration = getTrackingConfiguration(data, position)
  const linkTrackingAttributes = trackingConfiguration ? trackingConfiguration.linkAttributes : {}

  if (useNewTiles && template) {
    const trackingMethod = (event) => {
      const trackingData = getTrackingDataNewTile(data)
      if (!trackingData) return

      const trackingAndNavigation = createOnClickTrackingHandler(
        getNewTileTrackingConfig(trackingData),
        data.template?.tileDetails?.link?.href
      )
      if (trackingAndNavigation) {
        trackingAndNavigation(event)
      }
    }

    return (
      <TileWrapper
        tileProps={template}
        locale={locale}
        clickHandler={trackingMethod}
        customTileProps={{ skin: tileSkin }}
        extraProps={{
          primaryLinkConfig: { skin: 'unstyled' },
        }}
        hideDescription
      />
    )
  }
  return (
    <StandardTile skin={tileSkin} marginBottom={0}>
      <StandardTileOverlay>
        {calloutText && calloutColor && (
          <Callout skin={calloutColor} variant={getVariant(calloutColor)}>
            {calloutText}
          </Callout>
        )}
        {enableCampaignCallouts && mpv?.mpvId && <CampaignCallout product={mpv.mpvId} />}
      </StandardTileOverlay>
      <StandardTileImage>
        <BasicResponsiveImage aspectRatio={1} src={productImage.cloudinaryImage.fullSizeImage} alt={productImage.altTextOverride || ''} loading={loadType} />
      </StandardTileImage>
      <StandardTileContents>
        <Link href={transformedUrl.url} skin='unstyled' onClick={createOnClickTrackingHandler(trackingConfiguration, transformedUrl.url)} covering {...linkTrackingAttributes}>
          <StandardTileName>
            <Typography fontWeight='bold' fontSize='standard'>
              {productTileHeader ?? mpv?.productName}
            </Typography>
          </StandardTileName>
        </Link>
        {
          productTileSubHeader &&
            <StandardTileDescription>{productTileSubHeader}</StandardTileDescription>
        }
      </StandardTileContents>
    </StandardTile>
  )
}

const getVariant = (calloutColor) => {
  switch (calloutColor) {
    case 'standard':
    case 'new':
    case 'error': {
      return 'standard'
    }
    default:
      return 'inverse'
  }
}

export default Tile
