import { Column, Row } from "@vp/swan"
import React from "react"
import Tile from "./Tile"

//Displays tiles as a 2x2 grid on sm and xs screens for first feature tile row or when authoring option is selected
const GridTilesList = props => {
  const { tiles, tileSkin, enableCampaignCallouts, loadType, useNewTiles, trackingPageZone } = props
  const evenTiles = tiles.length % 2 === 0 ? tiles : tiles.slice(0, tiles.length - 1)
  return (
    <Column span={12}>
      <Row as="ul">
        {evenTiles.map((tile, index) => {
          return (
            <Column as="li" key={index} span={6} spanXs={6} spanSm={6}>
               <Tile
                loadType={loadType}
                data={tile}
                position={index + 1}
                trackingPageZone={trackingPageZone}
                enableCampaignCallouts={enableCampaignCallouts}
                useNewTiles={useNewTiles}
                tileSkin={tileSkin}
              />
            </Column>
          )
        })}
      </Row>
    </Column>
  )
}

export default GridTilesList