import { useEffect, useState } from 'react'
import {
  SWAN_STYLE_KEY_MAP,
  ScreenClassProvider
} from '@vp/swan'
import { useSwanStyleKeys } from '@vp/ubik-context'
import { TrackingProvider } from './hooks/TrackingContext'
import { CampaignProvider } from '@vp/campaign-callouts'
import TilesSection from './components/TilesSection'
import { isBrowser } from './utils/shared'
import { ContentfulContextProvider, DebugSection, ContentfulLink } from '@vp/mcs-react-tools'
import { should } from 'vitest'

export type AppProps = {
  gridToggle: boolean
  localizations: any
  tileRow: string
  useNewTiles: boolean
  enableCampaignCallouts: boolean,
  locale: string,
  tenant: string,
  eagerlyLoad: boolean,
  tileRowData: any,
  tileTrackingZone: string,
  tileRowId: string,
  contentfulMetadata: any,
  debugAvailability: any
}

export const Fragment = (props: AppProps) => {
  const [hashExists, setHashExists] = useState(false)
  useEffect(() => {
    setHashExists(isBrowser() && window.location.hash !== '')
  }, [])

  useSwanStyleKeys([
    SWAN_STYLE_KEY_MAP.core,
    SWAN_STYLE_KEY_MAP.grid,
    SWAN_STYLE_KEY_MAP.carousel,
    SWAN_STYLE_KEY_MAP.standardTile,
    SWAN_STYLE_KEY_MAP.utility,
  ])

  const search = typeof window !== 'undefined' ? window.location.search : ''

  const shouldRender = props.tileRowData && (
    (!props.useNewTiles && props.tileRowData.productTiles) ||
    (props.useNewTiles && props.tileRowData.tiles)
  )

  return (
    <ContentfulContextProvider space={props.contentfulMetadata?.spaceId}>
      <ContentfulLink id={props.contentfulMetadata?.contentful_id} />
      <DebugSection
        contentfulMetadata={props.contentfulMetadata}
        availability={props.debugAvailability}
        search={search}
      />
      <TrackingProvider canonicalUrl='https://www.vistaprint.com/'>
        <CampaignProvider tenant={props.tenant} locale={props.locale}>
          <ScreenClassProvider>
            {shouldRender && <TilesSection
              tileImageLoadingStrategy={props.eagerlyLoad || hashExists ? 'eager' : 'lazy'}
              tileData={props.tileRowData}
              tileRow={props.tileRow}
              tileTrackingZone={props.tileTrackingZone}
              id={props.tileRowId}
              enableCampaignCallouts={props.enableCampaignCallouts}
              useNewTiles={props.useNewTiles}
              localizations={props.localizations}
              gridToggle={props.gridToggle}
            />}
          </ScreenClassProvider>
        </CampaignProvider>
      </TrackingProvider>
    </ContentfulContextProvider>
  )
}
