import { Column, Row } from "@vp/swan"
import React from "react"
import Tile from './Tile'

const SWAN_COLUMNS = 12

const RowsTilesList = (props) => {
  const { tiles, visibleTiles, tileSkin, loadType, enableCampaignCallouts, trackingPageZone, useNewTiles } = props
  const tileColumnSpan = Math.round(SWAN_COLUMNS / visibleTiles)
  return (
    <Row as="ul">
      {tiles.map((tile, index) => {
        return (
          <Column
            as="li"
            span={tileColumnSpan}
            key={index}
          >
            <Tile
              loadType={loadType}
              data={tile}
              position={index + 1}
              trackingPageZone={trackingPageZone}
              enableCampaignCallouts={enableCampaignCallouts}
              useNewTiles={useNewTiles}
              tileSkin={tileSkin}
            />
          </Column>
        )
      })}
    </Row>
  )
}

export default RowsTilesList