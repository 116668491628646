import { Box, Row, Column, H2, GridContainer, BoundedContent, FlexBox, Link, useScreenClass } from '@vp/swan'
import CarouselTilesList from './CarouselTilesList'
import { createOnClickTrackingHandler, getTrackingConfigLinkAttributes } from './Analytics/trackingUtils'
import GridTilesList from './GridTilesList'
import RowTilesList from './RowTilesList'
import { useTrackingContext } from '../hooks/TrackingContext'
import { getTrackingConfig } from './Analytics/trackingConfig'

const defaultCarouselResponsiveSettings = [
  {
    breakpoint: 768,
    settings: {
      slidesToShow: 2.15,
      slidesToScroll: 2,
    },
  },
]

const TilesSection = props => {
  if (!props.tileData.productTiles.length) {
    return <></>
  }
  const { tileData, tileImageLoadingStrategy, tileTrackingZone, id, tileRow, useNewTiles, enableCampaignCallouts, localizations, gridToggle} = props
  const { sectionHeader, productTiles, tileSkin, callToAction, tileLayout } = tileData

  const customZone = tileRow ? `${tileTrackingZone}_${sectionHeader}_Row${tileRow}` : tileTrackingZone
  
  const { sourcePagePath } = useTrackingContext()

  const screenClass = useScreenClass()

  const isCtaUrlDefined = callToAction && callToAction[0] && callToAction[0].targetUrl
  const ctaUrl = isCtaUrlDefined ? callToAction[0].targetUrl.contentfulUrl : ""
  const ctaCopy = callToAction && callToAction[0] && callToAction[0].text
  const trackingData = { ctaCopy, sourcePagePath, ctaUrl, sectionHeader, row: tileRow, section: tileTrackingZone }

  const visibleTiles = tileLayout || 6

  const asCarousel = productTiles.length > visibleTiles
  
  return (
    <BoundedContent mt='0' mb='9'>
      <GridContainer>
        <Box mb='between-sections'>
          {sectionHeader && (
            <Row>
              <Column
                span={12}
                onClick={isCtaUrlDefined && createOnClickTrackingHandler(getTrackingConfig(trackingData), ctaUrl)}
                {...getTrackingConfigLinkAttributes(trackingData)}

              >
                <FlexBox alignItems='baseline' flexWrap='wrap' mb='6'>
                  {sectionHeader.trim().length > 0 && (
                    <H2 margin={0} fontSize='x2large' fontWeight='bold' fontSkin='title-section' mb="0">
                      {sectionHeader}
                    </H2>
                  )}
                  {isCtaUrlDefined && (
                    <Link ml="5" href={callToAction[0].targetUrl.contentfulUrl} skin="cta" style={{ whiteSpace: "nowrap" }}>
                      {callToAction[0].text}
                    </Link>
                  )}
                </FlexBox>
              </Column>
            </Row>
          )}
          {gridToggle && (screenClass === "xs" || screenClass === "sm") ?
            (<GridTilesList
                loadType={tileImageLoadingStrategy}
                useNewTiles={useNewTiles}
                enableCampaignCallouts={enableCampaignCallouts}
                tiles={productTiles}
                trackingPageZone={customZone}
                id={id}
                tileSkin={tileSkin}
              />) :
            (asCarousel || screenClass === "xs" || screenClass === "sm") ? (
              <CarouselTilesList
                loadType={tileImageLoadingStrategy}
                useNewTiles={useNewTiles}
                enableCampaignCallouts={enableCampaignCallouts}
                tiles={productTiles}
                trackingPageZone={customZone}
                id={id}
                carouselResponsiveSettings={defaultCarouselResponsiveSettings} //TODO fix
                tileSkin={tileSkin}
                visibleTiles={visibleTiles}
                localizations={localizations}
                tileRow={tileRow}
              />
            ): 
            (<RowTilesList
                loadType={tileImageLoadingStrategy}
                useNewTiles={useNewTiles}
                enableCampaignCallouts={enableCampaignCallouts}
                tiles={productTiles}
                trackingPageZone={customZone}
                id={id}
                carouselResponsiveSettings={defaultCarouselResponsiveSettings} //TODO fix
                tileSkin={tileSkin}
                visibleTiles={visibleTiles}
              />)
          }
        </Box>
      </GridContainer>
    </BoundedContent>
  )
}

export default TilesSection
