/* eslint-disable @stylistic/jsx-indent */
import { Column, Carousel, Row, CarouselSlide } from '@vp/swan'
import Tile from './Tile'

const CarouselTilesList = (props) => {
  const {
    tiles,
    visibleTiles,
    carouselResponsiveSettings,
    loadType,
    enableCampaignCallouts,
    trackingPageZone,
    id,
    tileSkin,
    useNewTiles,
    localizations,
    tileRow
  } = props

  const { previousSlide, nextSlide } = localizations

  return (
    <Row>
      <Column span={12} pr={"2"} pl={"2"}>
        <Carousel
          slidesToShow={visibleTiles}
          slidesToScroll={visibleTiles}
          infinite={false}
          gridGutters
          progressIndicator="none"
          arrows={true}
          responsive={carouselResponsiveSettings}
          accessibleTextForPrevious={previousSlide}
          accessibleTextForNext={nextSlide}
          arrowVerticalPosition={getCarouselArrowsVerticalPosition(tileRow)}
          id={id.replaceAll(" ", "")}
        >
          {tiles.map((tile, index) => (
            <CarouselSlide data-testid="slick-item" key={index}>
              <Tile
                loadType={loadType}
                data={tile}
                position={index + 1}
                trackingPageZone={trackingPageZone}
                enableCampaignCallouts={enableCampaignCallouts}
                useNewTiles={useNewTiles}
                tileSkin={tileSkin}
              />
            </CarouselSlide>
          ))}
        </Carousel>
      </Column>
    </Row>
  )
}

export const getCarouselArrowsVerticalPosition = (row) => {
  switch (row) {
    case "5":
      return "35%"
    default:
      return "40%"
  }
}


export default CarouselTilesList
